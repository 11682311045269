export default {
    StreamFormat: {
        UNKNOWN: {value: 0, label: "Unknown"},
        MMS: {value: 1, label: "MMS"},
        RTSP: {value: 2, label: "RTSP"}
    },
    OrganizationType: {
        UNKNOWN: {value: 0, label: "Unknown"},
        EDUCATION: {value: 1, label: "Giáo dục"},
        COMPANY: {value: 2, label: "Văn phòng"},
        SHOP: {value: 3, label: "Cửa hàng"}
    },
    OrganizationBranchType: {
        UNKNOWN: {value: 0, label: "Unknown"},
        HEAD_OFFICE: {value: 1, label: "Trụ sở chính"},
        BRANCH: {value: 2, label: "Chi nhánh"}
    },
    convertValueToName: (enumObj, value) => {
        if (!enumObj) return null;
        var obj = Object.values(enumObj).find(item => item.value == value)
        return obj && obj.label
    }
}

