<template>
    <div>
        <vx-card>
            <div slot="no-body" class="tabs-container px-6 pt-6">
                <vs-tabs v-model="activeTab" class="tab-action-btn-fill-conatiner">
                    <vs-tab label="Ca làm việc" icon-pack="feather" icon="icon-settings">
                        <div class="tab-text">
                            <div id="branch-tab-info">
                                <!-- Content Row -->
                                <div class="vx-row">
                                    <div class="vx-col md:w-1/3 w-full">
                                        <vs-input class="w-full mb-4" label="Loại" v-model="workShiftInfo.name"
                                                  v-validate="'required'"/>
                                    </div>
                                </div>

                                <div class="vx-row">
                                    <div class="vx-col w-full mb-4 mt-4">
                                        <h5>Cài đặt</h5>
                                    </div>
                                </div>
                                <div class="vx-col" style="padding-top: 30px">
                                    <feather-icon icon="PlusIcon" svgClasses="h-5 w-5 hover:text-danger cursor-pointer"
                                                  @click="addNewRecord(0)"/>
                                </div>

                                <div class="vx-row md:mb-1 mb-4"
                                     v-for="(item, index) in workShiftInfo.workShiftSetting">
                                    <div class="vx-col lg:w-1/4 w-full">
                                        <vs-input class="w-full" label="Tên khoảng" v-model="item.name"
                                                  v-validate="'required'"/>
                                    </div>
                                    <div class="vx-col lg:w-1/6 md:w-1/2 w-1/2">
                                        <label class="vs-input--label">Thời gian bắt đầu</label>
                                        <v-select :value="item.startHourString" :clearable="false" :options="timeSpans"
                                                  @input="startHour => updateTimeSpan(item, startHour, 'startHour')"
                                                  v-validate="'required'">
                                                  <span slot="no-options">{{$t('menuOrganization.emptyList')}}</span>
                                        </v-select>
                                    </div>
                                    <div class="vx-col lg:w-1/6 md:w-1/2 w-1/2">
                                        <label class="vs-input--label">Thời gian kết thúc</label>
                                        <v-select :value="item.endHourString" :clearable="false" :options="timeSpans"
                                                  @input="endHour => updateTimeSpan(item, endHour, 'endHour')"
                                                  v-validate="'required'">
                                                  <span slot="no-options">{{$t('menuOrganization.emptyList')}}</span>
                                        </v-select>
                                    </div>
                                    <div class="vx-col lg:w-1/4 md:w-1/2 w-1/2">
                                        <vs-input class="w-full" label="Linh động(phút)" v-model="item.bufferTime"
                                                  v-validate="'required|numeric'"/>
                                    </div>
                                    <div class="vx-col" style="padding-top: 30px">
                                        <feather-icon icon="PlusIcon"
                                                      svgClasses="h-5 w-5 hover:text-danger cursor-pointer"
                                                      @click="addNewRecord(index + 1)"/>
                                    </div>
                                    <div class="vx-col" style="padding-top: 30px">
                                        <feather-icon icon="TrashIcon"
                                                      svgClasses="h-5 w-5 hover:text-danger cursor-pointer"
                                                      @click="confirmDeleteRecord(index)"/>
                                    </div>
                                </div>

                                <!-- Save & Reset Button -->
                                <div class="vx-row">
                                    <div class="vx-col w-full">
                                        <div class="mt-8 flex flex-wrap items-center justify-end">
                                            <vs-button class="ml-auto mt-2" @click="save_changes"
                                                       :disabled="!validateForm">{{ isUpdate ? 'Cập nhật' : 'Tạo mới' }}
                                            </vs-button>
                                            <vs-button class="ml-4 mt-2" type="border" color="warning"
                                                       @click="workShiftList">Quay lại
                                            </vs-button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </vs-tab>
                </vs-tabs>
            </div>
        </vx-card>
    </div>
</template>

<script>

import utils from "../../../components/utils";
import vSelect from 'vue-select';
import _enum from '../../../enum';

export default {
    components: {
        vSelect
    },
    data() {
        return {
            activeTab: 0,
            workShiftInfo: [],
            isUpdate: false,
            timeSpans: [],
            selectedWorkShiftId: {}
        }
    },
    created() {
        let timeDuration = 30, timeSpans = [];
        for (let i = 0; i < 24 * 60; i = i + timeDuration) {
            timeSpans.push({value: i, label: utils.minuteToHour(i)});
        }
        this.timeSpans = timeSpans;
        let workShiftId = this.$route.query.id;

        this.$vs.loading();
        this.$crm.post('/work-shift/master/find-by-type/' + workShiftId).then((response) => {
            this.workShiftInfo = ((workShiftInfo) => {
                workShiftInfo.name = _enum.convertValueToName(_enum.OrganizationType, workShiftInfo.organizationType)
                workShiftInfo.workShiftSetting.map(item => {
                    item.startHourString = this.timeSpans.find(timeSpan => timeSpan.value == item.startHour).label;
                    item.endHourString = this.timeSpans.find(timeSpan => timeSpan.value == item.endHour).label;
                });
                return workShiftInfo;
            })(response.data);
            this.$vs.loading.close();
        }).catch((err) => {
            this.$vs.loading.close();
            return this.$vs.notify({
                text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                iconPack: 'feather',
                icon: 'icon-alert-circle',
                color: 'danger'
            });
        });

        if (workShiftId)
            this.isUpdate = true;

    },
    computed: {
        validateForm() {
            return !this.errors.any()
        },
    },
    methods: {
        updateTimeSpan(setting, hour, type) {
            setting[type] = hour.value;
            setting[`${type}_string`] = hour.label;
        },
        save_changes() {
            if (!this.validateForm) return;

            this.$vs.loading();
            this.$crm.post('/work-shift/master/create', this.workShiftInfo).then(() => {
                this.$vs.notify({
                    color: 'success',
                    text: `${this.isUpdate ? 'Cập nhật' : 'Thêm'} thành công`,
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                });
                this.$vs.loading.close();
                this.$router.push('/admin/manage-master-work-shift').catch(() => {
                })
            }).catch((err) => {
                this.$vs.loading.close();
                return this.$vs.notify({
                    title: 'Error',
                    text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                });
            });
        },
        workShiftList() {
            this.$router.push(`/admin/manage-master-work-shift?id=${this.$route.query.branchId}`).catch(() => {
            })
        },
        addNewRecord(index) {
            this.workShiftInfo.workShiftSetting.splice(index, 0, {})
        },
        confirmDeleteRecord(index) {
            this.selectedWorkShiftId = index;
            this.$vs.dialog({
                type: 'confirm',
                color: 'danger',
                title: `Xác nhận xoá`,
                text: `Bạn có chắc là muốn xoá`,
                accept: this.deleteRecord,
                acceptText: "Xoá",
                cancelText: "Huỷ"
            })
        },
        deleteRecord() {
            this.workShiftInfo.workShiftSetting.splice(this.selectedWorkShiftId, 1)
        }
    },
}
</script>

<style scoped>

</style>
